<template>
    <div style="background:#fff;">
        <div style="height:591px;overflow-y: auto;">
            <el-table :data="list" stripe style="width: 100%;border:1px solid #EBEEF5;">
                <!-- <el-table-column prop="date" label="产品" width="380">
                    <template slot-scope="scope">
                        <div class="flex">
                            <div class="left-coverImg flex-justify-between">
                                <img v-if="scope.row.photo_render && scope.row.photo_render.length>0" :src="imgUrl+scope.row.photo_render[0]+'!width_250px'" alt="">
                            </div>
                            <div style="text-align:left;" class="flex-1 flex-justify-between">
                                <h3 class="font14-grey ellipsis2">{{scope.row.name}}</h3>
                                <p v-if="companyName" style="margin-bottom:0px;" class="font14-grey1">{{companyName}}</p>
                            </div>
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="share_user" label="分享人">
                    <template slot-scope="scope">
                        <div>{{scope.row.share_user.name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="n_visited" label="被查看">
                    <template slot-scope="scope">
                        <div>{{scope.row.n_visited}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <div style="padding:15px 0;text-align:right;">
          <el-pagination background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page"
            :page-size="queryOpt.pagesize"
            :total="total"
          ></el-pagination>
        </div> -->

    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { xsscShareList } from '@/service/mobile';
    export default {
        components: {
            
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popVisible: false,
                popTitle: null,
                queryOpt:{
                    page:1,
                    pagesize:15,
                },
                total:0,
                list: [],
            }
        },
        watch: {
            queryOpt: {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        async created() {
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            refreshItems() {
                console.log('xsscShareList');
                xsscShareList().then(rst => {
                    // this.total = rst.total;
                    this.list = rst;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped>
    
    
</style>