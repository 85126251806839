<!-- 询价单 -->
<template>
  <div class="zt-page-content" style="height:calc(100% - 100px);overflow-y:hidden;">
    <div class="thead">
        <el-row>
        <el-col :span="8">商品名称</el-col>
        <el-col :span="8">型号</el-col>
        <el-col :span="8">申请数量</el-col>
        </el-row>
    </div>
    <div style="min-height:540px;overflow-y:auto;" class="table flex-1">
        <div v-if="list && list.length>0" class="tbody">
          <div v-for="(item,idx) in list" :key="idx" class="order-block">
              <div class="order-head flex">
              <div class="flex-1">日期：{{item.createdAt | moment("YYYY-MM-DD")}}</div>
              <div class="flex-1">
                  状态：
                  <span v-if="item && item.state==1">待确认</span>
                  <span v-if="item && item.state==2">已应答</span>
              </div>
              <div class="flex-1 ellipsis">申请询价企业：{{item.company}}</div>
              <div class="flex-1 ellipsis">询价人：{{item.name}}</div>
              <div class="flex-1 ellipsis">分享人：{{userName}}</div>
              </div>
              <div class="order-body">
              <el-row v-for="(item1,idx1) in item.entries" :key="idx1">
                  <el-col :span="8">
                  <a @click="gotoDetail(item1.item)" class="ellipsis">{{item1.item.name}}</a>
                  </el-col>
                  <el-col :span="8">{{item1.itemSku.moduleNo}}</el-col>
                  <el-col :span="8">{{item1.count}}</el-col>
              </el-row>
              </div>
              <div class="order-foot flex">
              <div style="text-align:right;" class="flex-1">
                  <el-button @click="order_detail(item)" size="small" type="text">查看详情及签订合同</el-button>
              </div>
              </div>
          </div>
        </div>
        <div v-else class="flex flex-v flex-center font14-grey1" style="min-height:540px">暂无数据</div>
    </div>
    <div style="padding:15px 0;text-align:right;">
        <el-pagination background
        layout="prev, pager, next"
        :current-page.sync="queryOpt.page.pageNumber"
        :page-size="queryOpt.page.pageCount"
        :total="total"
        ></el-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { IMG_URL_PRE } from "@/config";
import { enquiryListSeller } from "@/service/mobile";

export default {
  components: {},
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "",
      queryOpt: {
        page: {
          pageNumber: 1,
          pageCount: 5
        },
        where: {
          state: null,
        },
        order: [["createdAt", "desc"]]
      },
      total: 0,
      list: [],
      userName:'',
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true
    }
  },
  created() {
    this.userName = this.$store.state.loginUser.user.name;
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
  },
  mounted() {
    this.refreshItems();
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    gotoDetail(item) {
        console.log(item);
        this.$router.push("/factory/zysp/detail/"+item.id);
    },
    refreshItems() {
        console.log("refreshItems");
        this.list = [];
        enquiryListSeller(this.queryOpt).then(rst => {
            this.total = rst.count;
            this.list = rst.rows;
            console.log(this.list);
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        });
    },
    order_detail(item) {
      this.$router.push("/factory/xssc/detail/" + item.id);
    },

  }
};
</script>
<style>
</style>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.block {
  padding: 13px 32px 63px;
  color: #808080;
}
.thead {
  background: #fff;
  border: 1px solid #EBEEF5;
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: bold;
  .el-col {
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
}
.table {
  .tbody {
    .order-block {
        margin-bottom: 16px;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
    //   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      .order-head {
        height: 48px;
        line-height: 48px;
        background-color: #F5F7FF;
        position: relative;
        text-align: center;
        font-size: 12px;
        color: #808080;
        > div:last-child {
          text-align: center;
        }
        a {
          position: absolute;
          right: 40px;
        }
      }
      .order-body {
        .el-row:nth-child(2n) {
            background: #FAFAFA;
        }
        .el-col {
          height: 48px;
          line-height: 48px;
          text-align: center;
          span {
            color: #ee1818;
          }
        }
      }
      .order-foot {
        height: 48px;
        line-height: 48px;
        padding: 0 15px;
        position: relative;
        text-align: center;
        border-top: 1px solid #f5f7fa;
        > div:last-child {
          text-align: center;
        }
        a {
          position: absolute;
          right: 40px;
        }
      }
    }
    .order-block:last-child {
        margin-bottom: 0;
    }
  }
}
</style>
